*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root,
html,
body,
.app-container {
  height: 100%;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}
body {
  font-family: "Work Sans", sans-serif;
  height: 100% !important;
}

:root {
  --mobile-font-size: 0.9rem;
  --green-color: #0bb5c7;
}
img {
  width: 100%;
}
img[alt] {
  font-size: 14px;
  font-weight: 300;
  /* background-color: #677f82; */
}
/* .btn{
  background-color: transparent;
  padding: 0.5rem;
  border: 1px solid rgb(147, 137, 137);
} */

input {
  font-weight: 300 !important;
}
input:focus {
  outline: 1px #0bb5c7 !important;
}
input::placeholder {
  font-weight: 300 !important;
}
.custom-p--modal {
  font-weight: 300 !important;
}

.sarch-form input {
  transition: all 200ms;
}

.sarch-form input:focus {
  border: 2px solid #0bb5c7;
  border-radius: 8px;
}

/* ****** app ********* */
.app {
  height: 100%;
  max-width: 500px;
  margin: auto;
  overflow: auto;
  box-shadow: 0px 1px 10px rgba(10, 14, 27, 0.15);
  /* overflow-x: hidden; */
  /* position: relative; */
}

/* ******************** login ****************** */
.titre-ins {
  color: #0bb5c7;
}
.inscrire {
  background-color: #0bb5c7;
  color: #fff;
}
.form input {
  border: 1px solid #0bb5c7;
}
.link {
  text-decoration: none;
  color: #0bb5c7;
}

/* ************************ register **************** */
.titre-ins {
  color: #0bb5c7;
}
.inscrire {
  background-color: #0bb5c7;
  color: #fff;
}
.inscrire:hover {
  background-color: #fff;
  color: #0bb5c7;
  border: 1px solid #0bb5c7;
}
.form input {
  border: 1px solid #0bb5c7;
}
.link {
  text-decoration: none;
  color: #0bb5c7;
}

form span {
  font-size: 0.8rem;
  font-weight: 300;
  margin: 0;
}

/* ************* accueil header ************* */
.home-container {
  height: 100%;
  position: relative;
}
.accueil-header {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 0.8rem 0;
}
.app-logo {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  border: 1px solid var(--green-color);
}
.sync-data {
  transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* box-shadow: 1px 0px 10px rgba(10, 14, 27, 0.4); */
  border-radius: 50px;
}
.sync-data:active {
  transform: scale(1.2);
  box-shadow: 1px 0px 10px rgba(10, 14, 27, 0.15);
}
@keyframes sync {
  0% {
  }
}

.header-fixed {
  top: 0;
  background-color: white;
}
.inside-scroll {
  position: relative;
  background-color: white;
  padding: 0 0.5rem;
  height: 100%;
  overflow: auto;
  padding-bottom: 1rem !important;
  /* margin-bottom: 2rem; */
}
.inside-scroll-container {
  background-color: rgba(11, 164, 180, 0.11);
  height: 100%;
  overflow: auto;
  padding: 0.5rem;
  padding-bottom: 4rem;
}
.footer-fixed {
  position: absolute;
  bottom: 5px;
  width: 100%;
  left: 0;
}

/* ************* tab-navlinks ************** */
.tab-navlink {
  text-decoration: none;
  color: black;
}
.tab-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  transition: all 150ms ease;
}

.tab-text {
  font-weight: 400;
  font-size: 0.8rem !important;
}

.active-tab {
  color: #e4b418;
  border-bottom: 1px solid #f8c10f;
  font-weight: 400 !important;
}

/* ********* vente ************ */
.ajout-vente {
  background-color: white;
  width: 100%;
  padding: 0.6rem 0.5rem;
}

.ajout-vente input {
  padding: 0.5rem;
  border: none;
  background-color: rgba(11, 164, 180, 0.11);
  border-radius: 5px;
  outline: none;
}

.initial-solde::placeholder {
  font-weight: 300;
  font-size: 0.8rem;
}

/* ****************** depense **************** */
.contaierCard {
  width: 100%;
  /* height: 70px; */
  /* border: 1px solid gray; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 5px;
  border-radius: 10px;
  margin: 0.5rem 0;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.imageContainer {
  width: 100px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.card-containt {
  flex: 1;
  padding: 0rem 0.5rem;
}

.card-containt p {
  margin: 0;
  font-weight: 400;
}

.card-containt time {
  font-weight: 400;
  font-size: 0.8rem;
}

.card-text {
  font-size: 0.68rem;
  font-weight: 400 !important;
}

.prix {
  color: #ef4140;
}

.image {
  width: 100%;
  height: 60px;
  object-fit: cover;
}

/* *************************** products ***************** */
.btn-primary {
  /* font-family: Raleway-SemiBold;
  font-size: 13px; */
  color: #0a7582;
  letter-spacing: 1px;
  line-height: 25px;
  /* border: 2px solid #0A7582; */
  border-radius: 40px;
  background: #0a7582 !important;
  border-color: #0a7582 !important;
}

.text-primary {
  color: #0a7582;
}

.btn-add-product {
  border-radius: 30px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  margin: auto !important;
  margin-right: auto !important;
}

.back-btn:hover {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.btn-edit {
  background-color: transparent;
  padding: 0.7rem;
  border: 1px solid #e4b418;
  background-color: #e4b418;
  color: white;
  border-radius: 8px;
}
.btn-delete {
  padding: 0.7rem;
}
.btn-edit:hover {
  opacity: 0.8;
}
.btn-edit:focus {
  opacity: 0.8;
  transform: translateY(5px);
}

/* ********************* historique ****************** */
.historique-filter {
  font-weight: 300;
  border: 1px solid #848585;
  border-radius: 50px;
  padding: 0.4rem 0.6rem;
  background-color: transparent;
}
.custom-historique-span {
  font-size: 13px;
  font-weight: 400;
}
.active-period {
  background-color: #52d5e4;
  color: white !important;
  font-weight: 400;
  transition: all 200ms ease-in-out;
  border: 1px solid #52d5e4;
}

/* ******************** skeleton animated loading *************** */
.image-splash {
  width: 55px;
  animation: splash 0.8s cubic-bezier(0.5, 0, 0.5, 0.8) infinite;
}
@keyframes splash {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  left: 28px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: auto;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0bb5c7 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  .tab-item {
    padding: 0 0.5rem;
  }

  .tab-text {
    font-size: var(--mobile-font-size);
  }

  /* ********** solde ************ */
  .solde {
    font-size: 0.95rem !important;
  }
  .eye-icon {
    font-size: 1.5rem !important;
  }
}
